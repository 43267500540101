/* PopupForm.css */
@keyframes headingAnimation {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.5;
    }
  }
  
  @keyframes submitAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .animate-heading {
    animation: headingAnimation 2s infinite;
  }
  
  .animate-submit {
    animation: submitAnimation 0.5s ease-in-out;
  }
  